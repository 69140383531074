import { nanoid } from 'nanoid';


// MENU DATA
export const premiumData = {
    sectionId: 'premium',
    helmet: 'Premium',
    title: 'Premium',
    name: '',
    subtitle: "",
    cta: '',
    image: 'premium',
    message: 'Subscribe now and unlock the features',
    backButton: true,
    navigation: [
        {
            title: 'Home',
            url: '/',
            local: false,

        },
        {
            title: 'Blog',
            url: '/blog',
            local: false,

        },
      
    ]
};

// FEATURES DATA
export const featuresData = [
    {
        id: nanoid(),
        img: 'Premium-shopping.jpg',
        title: 'Shop Efficiently, Save Money',
        info: 'Link accounts with another user and have 365 Nutrition combine your grocery list to simplify shopping. Combined grocery lists are linked and update instantly - Shop together and halve the time!',
        info2: "With 365 Nutrition's linked groceries you will only buy what you need. Avoid food wastage and start saving now!",
        url: '',

    },
    {
        id: nanoid(),
        img: 'Premium-prep.jpg',
        title: 'Be Prepared',
        info: "Unlock meal preparation for linked accounts. Prepare  meals in advance, save time and eat the right portions.",
        info2: "Cook enough food for a week, two weeks, a month - it's up to you!",
        url: '',
        repo: '', // if no repo, the button will not show up
    },
    {
        id: nanoid(),
        img: 'Premium-variety.jpg',
        title: 'Eat Healthy, Eat Variety',
        info: "Create additional meal plans and switch between them each week. Stop eating the same food each day and start enjoying some variety.",
        info2: "Remember, 365 Nutrition - It's a Lifestyle (not a diet!).",
        url: '',
        repo: '', // if no repo, the button will not show up
    },
    {
        id: nanoid(),
        img: 'Premium-more.jpg',
        title: '...and More!',
        info: "The features don't stop here. While you're taking advantage of the savings and spare time provided by 365 Nutrition, so are we! We didn't just make 365 Nutrition, we use it.",
        info2: "We are constantly looking to implement new features and improvements. If there is something you want, let us know.",
        url: '',
        repo: '', // if no repo, the button will not show up
    },
];