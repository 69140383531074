import { premiumData, featuresData } from '../mock/premium';

import React, { useState, useEffect } from 'react';
import { PortfolioProvider } from '../context/context';
import Hero from '../components/Hero/Hero';
import Features from '../components/Premium/Premium';


function PremiumPage() {

    const [hero, setHero] = useState({});
    const [projects, setProjects] = useState([]);
    

    useEffect(() => {
        setHero({ ...premiumData});
        setProjects([...featuresData]);

    }, []);

    return (
        <PortfolioProvider value={{ hero, projects }}>

      <Hero />
      <Features />
    </PortfolioProvider>
    );
}


export default PremiumPage